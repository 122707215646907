
import { Options, Vue } from 'vue-class-component';
import { ExperienceAffinityClient, ExperienceClient, PremiumClient, TryAgainClient } from '@/services/Services';
import LoveFaceShadowBar from '@/components/loveFaceShadowBar.vue';
import Question from '../components/question.vue';
import Answer from '../components/answer.vue';
import experienceAffinityNotReached from '../components/experience/experienceAffinityNotReached.vue';
import ExperienceAffinitySuccess from '../components/experience/experienceAffinitySuccess.vue';
import InfoModal from '@/components/modals/infoModal.vue';
import * as OM from '@/Model';
import BombTriggered from '../components/bombTriggered.vue';
import noPremiumModal from '../modals/premium/noPremiumModal.vue';
import store from '@/store';
import { newExperienceMatchCallback, newMatchCallback } from '@/afterPremiumActionCallbacks';
import BombTriggeredNotBlocked from '../components/bombTriggeredNotBlocked.vue';
import experienceAffinityResultReplierHasToConfirmAffinity from '../components/experienceAffinityResultReplierHasToConfirmAffinity.vue';

@Options({
	components: {
		LoveFaceShadowBar,
		Question,
		Answer,
		experienceAffinityNotReached,
        ExperienceAffinitySuccess,
        BombTriggered,
        BombTriggeredNotBlocked,
        experienceAffinityResultReplierHasToConfirmAffinity,
	}
})
export default class NewExperienceMatch extends Vue {

    affinityIdentifier: string = "";
    experienceIdentifier: string = "";
    creatorIdentifier: string = "";
	match: OM.NewMatchVm = new OM.NewMatchVm();
    loaded: boolean = false;
	error: boolean = false;
	premium: OM.PremiumActiveVM = null;

    affinityRateText: string = "";
	matchStatus = OM.ReplyStatus;
	questionIndex: number = 0;

    hideBombTriggeredButCanProceed: boolean = false;

    created() {
        this.affinityRateText = this.$localizationService.getLocalizedValue("app_AffinityRate:", "Affinity Rate:");
		this.affinityIdentifier = this.$route.params.affinityIdentifier.toString();
		this.experienceIdentifier = this.$route.params.experienceIdentifier.toString();
        
        this.init();
	}

    init() {
        Promise.all([
            ExperienceAffinityClient.getNewMatchInformations(this.affinityIdentifier),
            PremiumClient.getActive(),
            ExperienceClient.getById(this.experienceIdentifier)
        ])
        .then(xs => {
            this.match = xs[0];
            this.premium = xs[1];
            this.creatorIdentifier = xs[2].creator.identifier;
        })
        .catch(err => {
            this.error = true;
            this.$opModal.show(InfoModal, {
                img: 'face_error.svg',
                text: err.Message,
                confirm: this.$localizationService.getLocalizedValue("app_Experience", "Experience"),
                confirmCb: () => {
                    this.$router.push("/experience")
                    this.$opModal.closeLast();
                },
            })
        }).finally( () => {
            this.loaded = true;
        })
    }

    get isBombTriggeredAndCantProceed(){
        return this.match.isBombTriggered && !this.match.replierHasToConfirmAffinity;
    }

    get isBombTriggeredAndCanProceedAndInfoShow(){
        return this.match.isBombTriggered && this.match.replierHasToConfirmAffinity && !this.hideBombTriggeredButCanProceed;
    }

    get isBombTriggeredAndCanProceedAndInfoHide(){
        return this.match.isBombTriggered && this.match.replierHasToConfirmAffinity && this.hideBombTriggeredButCanProceed;
    }

    get showLayout(){
        return !this.showSuccessError && (!this.match.isBombTriggered || this.isBombTriggeredAndCanProceedAndInfoHide)
    }

	get currentQuestion() {
		var question = this.match.questions[this.questionIndex];
		return question;
	}

	get currentAnswer() {
		return this.match.questions[this.questionIndex];
	}

	get showAnswer(){
		if(this.questionIndex > this.match.questions.length - 1)
			return false;

		return this.match.questions[this.questionIndex].answerIndex != -1;
	}

    get showSuccessError() {
        return this.match.status == this.matchStatus.Completed && !this.showAnswer;
    }

	reply(answerIndex: number, answerText: string) {
		var replyModel = new OM.ExperienceReplyMatchQuestionVm();
        replyModel.experienceIdentifier = this.experienceIdentifier;
        replyModel.matchIdentifier = this.match.matchIdentifier;
		replyModel.questionIndex = this.questionIndex;
		replyModel.answerIndex = answerIndex;
		replyModel.answerText = answerText;

		ExperienceAffinityClient.replyQuestion(replyModel)
		.then(x => {
			this.match = x;

            this.scrollPagePaddingTop();
		})
        .catch(err => {
            this.$opModal.show(InfoModal, {
                img: 'face_error.svg',
                text: err.Message,
                confirm: this.$localizationService.getLocalizedValue("app_Chiudi", "Chiudi"),
                confirmCb: () => {
                    this.$opModal.closeLast();
                },
            })
        })
	}

    canTryAgain() {
        TryAgainClient.canTryAgain(false)
        .then( x => {
            if(!x){
                this.cannotUseTryAgain();
                return;
            }

            this.useTryAgain();
        })
    }

    cannotUseTryAgain(){
        var text = this.$localizationService.getLocalizedValue("app_HaiFinitoIRiprovaDisponibili!", "Hai finito i Riprova disponibili!");

        if(this.premium) {
            this.$opModal.show(InfoModal, {
                text: text,
                confirm: this.$localizationService.getLocalizedValue("app_HoCapito", "Ho capito"),
                confirmCb: () => {
                    this.$opModal.closeLast();
                }
            })

            return;
        }

        this.$opModal.show(noPremiumModal, {
            text: text,
            actionText: this.$localizationService.getLocalizedValue("app_DiventaPremium", "Diventa premium"),
            callback: () => {
                this.$opModal.closeLast();
                store.state.afterPremiumAction = newExperienceMatchCallback(this.match.matchIdentifier, this.experienceIdentifier);

                this.$router.push('/premium/');
            }
        })
    }

    useTryAgain(){
        this.$opModal.show(InfoModal, {
            text: this.$localizationService.getLocalizedValue("app_ConfermiDiVolerUtilizzareIlRiprova?", "Confermi di voler utilizzare il \"Riprova\"?"),
            confirm: this.$localizationService.getLocalizedValue("app_Si", "Si"),
            deny: this.$localizationService.getLocalizedValue("app_No", "No"),
            confirmCb: () => {
                this.$opModal.closeLast();

                TryAgainClient.useTryAgain(this.match.matchIdentifier)
                .then(x => {
                    this.questionIndex = 0;
                    this.init();
                })
                .catch(err => {
                    this.$opModal.show(InfoModal, {
                        img: 'face_error.svg',
                        text: err.Message,
                        deny: this.$localizationService.getLocalizedValue("app_Chiudi", "Chiudi"),
                        denyCb: () => {
                            this.$opModal.closeAll();
                        }
                    })
                })
            },
            denyCb: () => {
                this.$opModal.closeLast();
            }
        })
    }

    scrollPagePaddingTop(){
        var el = this.$refs.page_padding_scrollable as HTMLElement;
        el.scrollTop = 0;
    }

	prevQuestion() {
		if(this.questionIndex > 0){
			this.questionIndex = this.questionIndex - 1;
            this.scrollPagePaddingTop();
        }
	}

	nextQuestion() {
		if(this.questionIndex < this.match.questions.length){
			this.questionIndex = this.questionIndex + 1;
            this.scrollPagePaddingTop();
        }
	}

	openAnswerDetail(questionIndex: number) {
		this.questionIndex = questionIndex;
	}

}
