
import { Options, Vue } from 'vue-class-component';
import { ExperienceAffinityClient, TryAgainClient } from '@/services/Services';
import { Prop } from 'vue-property-decorator';
import LoveFaceShadowBar from '@/components/loveFaceShadowBar.vue';
import * as OM from '@/Model';
import ExperienceAffinitySuccessModal from '../../modals/experience/experienceAffinitySuccessModal.vue';
import store from '@/store';

@Options({
    components: {
        LoveFaceShadowBar
    }
})
export default class ExperienceAffinityNotReached extends Vue {

    @Prop() chatRoomIdentifier: string;
    @Prop() experienceIdentifier: string;
    @Prop() affinityIdentifier: string;

    @Prop() creatorIdentifier: string;

    @Prop({
        default: 0
    }) affinity: number;

    @Prop({
        default: 0
    }) minimumAffinityRate: number;

    @Prop({
        default: null
    }) premium: OM.PremiumActiveVM;

    @Prop({
        default: ''
    }) userName: string;

    affinityRateText: string = "";
    tryAgainSummary = new OM.TryAgainSummaryVM();
    userIdentifier: string = store.state.loggedUser.identifier;

    created() {
        this.affinityRateText = this.$localizationService.getLocalizedValue("app_AffinityRate:", "Affinity Rate:");

        

        this.init();
    }

    init() {
        Promise.all([
            TryAgainClient.getTryAgainSummary(this.affinityIdentifier, false),
        ])
        .then(xs => {
            this.tryAgainSummary = xs[0];
        })
    }

    get endDate(){
        if(this.tryAgainSummary && this.tryAgainSummary.nextTryAgainAvailable)
            return new Date(this.tryAgainSummary.nextTryAgainAvailable);

        return null;
    }

    get amICreator(){
        return this.userIdentifier == this.creatorIdentifier;
    }

    nextItem() {
        if(this.chatRoomIdentifier){
            this.$router.replace("/chats/" + this.chatRoomIdentifier);
            return;
        }

        this.$router.replace("/experience")
        .then( () => {
            if(this.amICreator){
                this.$router.push("/my-experience-list/")
                .then( () => {
                    this.$router.push("/experience/detail/" + this.experienceIdentifier)
                })
            }
        })
    }

    candidate(){
        ExperienceAffinityClient.candidateToExperience(this.experienceIdentifier, this.affinityIdentifier)
        .then(x => {
            this.$opModal.show(ExperienceAffinitySuccessModal, {
                userName: this.userName,
            })
        });
    }
}
