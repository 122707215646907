
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ExperienceAffinityClient } from '@/services/Services';
import ExperienceAffinitySuccessModal from '../../modals/experience/experienceAffinitySuccessModal.vue';
import LoveFaceShadowBar from '@/components/loveFaceShadowBar.vue';
import InfoModal from '@/components/modals/infoModal.vue';
import bodymovin from "lottie-web";
import * as VM from '@/viewModel';
import * as OM from '@/Model';

@Options({
    components: {
        LoveFaceShadowBar
    }
})
export default class ExperienceAffinitySuccess extends Vue {

    @Prop({
        default: ""
    }) experienceIdentfier: string;

    @Prop({
        default: () => new OM.NewMatchVm()
    }) match: OM.NewMatchVm;

    @Prop({
        default: 0
    }) affinity: number;

    @Prop({
        default: () => []
    }) answers: OM.NewMatchQuestion[];

    @Prop({
        default: ""
    }) chatRoomIdentifier: string;

    affinityRateText: string = "";
    animationDone: boolean = false;

    created() {
        this.affinityRateText = this.$localizationService.getLocalizedValue("app_AffinityRate:", "Affinity Rate:");
        setTimeout(() => {
            this.animationDone = true;
        }, 2800);
    }

    mounted() {
        setTimeout(() => {
            var animation = bodymovin.loadAnimation({
                container: <any>this.$refs.lottieAnimation,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                path: './json/registrazioneriuscita.json'
            })

            animation.play();
            animation.loop = false;
        }, 300);
    }

    removeAffinity() {
        this.$opModal.show(InfoModal, {
            text: this.$localizationService.getLocalizedValue("app_UnaVoltaEliminataAffinitàNonPotràEssereRecuperata", "Una volta eliminata l'affinità non potrà più essere recuperata. <br />Vuoi procedere?"),
            confirm: this.$localizationService.getLocalizedValue("app_si", "Si"),
            confirmCb: () => {
                this.$opModal.closeLast();
                ExperienceAffinityClient.takeOffAffinity(this.match.matchIdentifier)
                .then(x => {
                    this.$router.replace("/experience");
                })
            },
            deny: this.$localizationService.getLocalizedValue("app_no", "No"),
            denyCb: () => {
                this.$opModal.closeLast();
            }
        })
    }

    candidate() {
        ExperienceAffinityClient.candidateToExperience(this.experienceIdentfier, this.match.matchIdentifier)
        .then(x => {
            this.$opModal.show(ExperienceAffinitySuccessModal, {
                userName: this.match.personName,
            })
        })
    }

    goToChat() {
        this.$router.replace('/chats/' + this.chatRoomIdentifier);
    }
}
